import React from "react";
import { Link } from "gatsby";

import logo from "../img/logo.svg";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import vimeo from "../img/social/vimeo.svg";
import style from "../scss/components/Footer.module.scss";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className={style.footer}>
        <img src={logo} className={style.footer__logo_img} />
        <div className={style.footer__links}>
          <a href="/about">about</a>
        </div>
      </footer>
    );
  }
};
const Stretcher = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
  }
  componentDidMount() {
    const emptySpaceHeight = window.innerHeight - document.body.clientHeight;
    if (emptySpaceHeight > 0) this.setState({ height: emptySpaceHeight });
  }
  render() {
    return <div style={{ height: this.state.height }}></div>;
  }
};

export default Footer;
