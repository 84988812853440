import React from "react";
import { Link } from "gatsby";
import github from "../img/github-icon.svg";
import logo_h from "../img/logo-h.svg";
import style from "../scss/components/Navbar.module.scss";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <nav
        className={style.navbar}
        role="navigation"
        aria-label="main-navigation"
      >
        <div className={style.navbar__logo}>
          <Link to="/" className={style.navbar__logo__link}>
            <img src={logo_h} className={style.navbar__logo__image} />
          </Link>
        </div>
      </nav>
    );
  }
};

export default Navbar;
